import React from "react"
import Axios from "axios";

export class ResultsLogin extends React.Component {

    componentDidMount() {
        
    }

    async handleSubmit(e) {
        e.preventDefault()

        try {
            const data = (await Axios.post("https://api.inmunimed.pe/clients", {
                "type": "person",
                "doc": e.target.document.value,
                "bdate": e.target.fnac.value
            })).data

            sessionStorage.setItem("loginType", "person")
            sessionStorage.setItem("client", JSON.stringify(data.client))
            window.location.href = "/resultados"
        } catch (error) {
            alert("Documento o Fecha erroneos")
            console.error(e.response)
        }
    }


    async handleBussinessSubmit(e) {
        e.preventDefault()

        try {
            const data = (await Axios.post("https://api.inmunimed.pe/clients", {
                "type": "bussiness",
                "doc": e.target.document.value,
                "contact_number": e.target.contact_number.value
            })).data

            sessionStorage.setItem("loginType", "bussiness")
            sessionStorage.setItem("client", JSON.stringify(data.client))
            window.location.href = "/resultados"
        } catch (error) {
            alert("RUC o Número de contacto erroneos")
            console.error(e.response)
        }
    }
    /**
     * 
     * @param {Event} e 
     */
    showPassword(e){
        var x = e.target.parentNode.querySelector('.password')
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
    }
    render() {
        return(
            <div className="page login content-result">
                <h1>Resultados en línea</h1>
                <main >
                    <figure data-dialog="person">
                        <img src={require("../Assets/images/iconos/bg-1.png")}></img>
                        <figcaption>Pacientes Particulares</figcaption>
                        <a href="http://inmunimed.tenmalab.com/loginpaciente.html" target="_blank"className="btn-continue">
                            Continuar
                        </a>
                    </figure>
                    <figure data-dialog="bussiness">
                        <img src={require("../Assets/images/iconos/bg-2.png")}></img>
                        <figcaption>Empresas e Instituciones</figcaption>
                        <a href="http://inmunimed.tenmalab.com/loginempresa.html" target="_blank" className="btn-continue">
                            Continuar
                        </a>
                    </figure>
                    <figure data-dialog="bussiness">
                        <img src={require("../Assets/images/iconos/bg-3.png")}></img>
                        <figcaption>Médicos</figcaption>
                        <a href="http://inmunimed.tenmalab.com/loginmedico.html" target="_blank" className="btn-continue">
                            Continuar
                        </a>
                    </figure>

                    <dialog id="person" class="site-dialog">
                        <form onSubmit={(e)=>this.handleSubmit(e)}>                            
                            <header class="dialog-header">
                                <h1>Ingrese sus datos</h1>
                            </header>
                            <div class="dialog-content">
                                <label>Documento</label>
                                <div className="row">
                                    <select className="form-control col-5 ml-3 mr-1">
                                        <option>Tipo de documento</option>
                                        <option>DNI</option>
                                        <option>CE</option>
                                        <option>PTP</option>
                                        <option>OTRO</option>
                                    </select>
                                    <input name="document" type="text" className="form-control pl-3 ml-6 col-6" placeholder="N° documento" required></input>
                                </div>
                                <label>Contraseña</label>
                                {/* <input type="date" timezone="UTC-5" name="fnac" className="form-control pl-3" required></input> */}
                                <input type="password" name="fnac" placeholder="Ingrese su Contraseña" className="form-control pl-3 password" required></input>
                                <input type="checkbox" onClick={e=>this.showPassword(e)}/><span className="show-password">Mostrar contraseña</span>
                            </div>
                            <div class="btn-group cf">
                                <button type="reset" class="btn btn-cancel" id="cancel">Cancelar</button>
                                <button type="submit" class="btn btn-danger" id="delete">Ingresar</button>
                            </div>
                        </form>
                    </dialog>
                    
                    <dialog id="bussiness" class="site-dialog">
                        <form onSubmit={(e)=>this.handleBussinessSubmit(e)}>                            
                            <header class="dialog-header">
                                <h1>Ingrese sus datos</h1>
                            </header>
                            <div class="dialog-content">
                                <label>Documento</label>
                                <input name="document" type="tel" className="form-control pl-3" placeholder="RUC" required></input>
                                <label>Número de contácto</label>
                                <input name="contact_number" type="tel" className="form-control pl-3" placeholder="Número de contacto" required></input>
                            </div>
                            <div class="btn-group cf">
                                <button type="reset" class="btn btn-cancel" id="cancel">Cancelar</button>
                                <button type="submit" class="btn btn-danger" id="delete">Ingresar</button>
                            </div>
                        </form>
                    </dialog>
                    
                </main>
            </div>
        )
    }
}