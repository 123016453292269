import React, {Component, Suspense} from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import { Header, Footer } from "Layout"
import ScrollToTop from "Components/Others/ScrollToTop"
import { Inicio, LabResults } from "Views"
import { ResultsLogin } from 'Views/ResultsLogin';
import Cart from 'Components/cart/cart';
import Popup from 'Components/popup/popup';

export default class MyRouter extends Component {
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <div id="main-content">
                        <Suspense fallback={<h1></h1>}>
                        <Switch>
                            <Route path="/">
                                <div className="frontend">
                                    <Header/>
                                    {/* <Popup></Popup> */}
                                    {/* <Cart></Cart> */}
                                 
                                    <Route path="/" exact component={Inicio} />
                                    <Route path="/resultados/login" exact component={ResultsLogin} />
                                    <Route path="/resultados" exact component={LabResults} />
                                    <Footer />
                                </div>
                            </Route>
                        </Switch>
                        </Suspense>
                    </div>
                </ScrollToTop>
            </Router>
        )
    }
}
