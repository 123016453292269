import React, { Component } from 'react'
import "./popup.scss"
import $ from "jquery"
export default class Popup extends Component {
    constructor(props){
        super(props)
        this.moveScroll = this.moveScroll.bind(this)
    }
    componentDidMount(){
        document.querySelector(".close").addEventListener("click",()=>{
            document.querySelector(".Popup").classList.add("opacity")
        })
    }
    moveScroll(){
        $('html, body').animate({
            scrollTop: $("#myContact").offset().top
        }, 0);
        document.querySelector(".Popup").classList.add("opacity")
    }
    close(){
        document.querySelector(".Popup").classList.add("opacity")
    }
    render() {
        return (
        <div className="Popup">
            <a ><img className="img-popup" src={require("../../Assets/images/iconos/popup-dia-del-padre.png")} ></img></a>
            <div className="close" onClick={()=>{this.close()}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.971 47.971"><g><path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"></path></g></svg>
            </div>
        </div>
        )
    }
}
