import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer className="footer" style={{backgroundColor:'#f7f8f9'}}>
                <div className="container">
                    <div className="content-part1 d-flex justify-content-center justify-content-md-between">
                        <div className="logo" style={{width:180}}>
                            <a href="/">
                                <img src={require("Assets/images/logo.svg")} className="img img-fluid" />
                            </a>
                        </div>
                        {/* <div className="menu-footer d-flex justify-content-between">
                            <ul>
                                <li><a href="#">Inicio</a></li>
                                <li><a href="#">Análisis</a></li>
                                <li><a href="#">Servicios</a></li>
                            </ul>
                            <ul>
                                <li><a href="#">Nosotros</a></li>
                                <li><a href="#">Certificaciones</a></li>
                                <li><a href="#">Historia y metas</a></li>
                            </ul>
                            <ul>
                                <li><a href="#">Servicios</a></li>
                                <li><a href="#">Análisis Clínicos</a></li>
                                <li><a href="#">Vacunas</a></li>
                                <li><a href="#">ADN</a></li>
                            </ul>
                            <ul>
                                <li><a href="#">Blog</a></li>
                                <li><a href="#">Noticias</a></li>
                                <li><a href="#">Videos</a></li>
                            </ul>
                            <ul>
                                <li><a href="#">Catálogo</a></li>
                                <li><a href="#">Categoría uno</a></li>
                                <li><a href="#">Categoría dos</a></li>
                            </ul>
                        </div> */}
                        <div className="contact">
                        <ul className="d-flex">
                                <li>
                                    <i class="fas fa-envelope"></i>
                                    <a href="mailto:ventas@inmunimed.pe">ventas@inmunimed.pe</a>
                                </li>
                                <li>
                                    <i class="fas fa-envelope"></i>
                                    <a href="mailto:informes@inmunimed.pe">informes@inmunimed.pe</a>
                                </li>
                            </ul>
                        </div>
                        <div className="social">
                            <ul className="d-flex">
                                {/* <li>
                                    <a href="https://instagram.com/inmunimed.pe">
                                        <img src={require("Assets/images/footer/instagram.svg")} className="img img-fluid" style={{width:20}}/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/inmunimed/" target="_blank">
                                        <img src={require("Assets/images/footer/facebook.svg")} className="img img-fluid" style={{width:20}}/>
                                    </a>
                                </li> */}
                                {/* <li>
                                    <a href="#">
                                        <img src={require("Assets/images/footer/linkedin.svg")} className="img img-fluid" style={{width:20}}/>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <img src={require("Assets/images/footer/youtube.svg")} className="img img-fluid" style={{width:25}}/>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="content-part2 d-flex justify-content-between">
                        <div className="content-menu">
                            <ul className="d-flex menu-copyright">
                                <li>
                                    <a href="#">Legal</a>
                                </li>
                                <li>
                                    <a href="#">Privacidad</a>
                                </li>
                                <li>
                                    <a href="#">Políticas</a>
                                </li>
                                <li>
                                    <a href="#">Cookies</a>
                                </li>
                            </ul>
                        </div>
                        <div className="copyright">
                            &copy; 2020 Inmunimed
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
