import React, { Component } from 'react'
import MediaQuery from 'react-responsive';
import $ from "jquery"

export class Header extends Component {
    constructor(props){
        super(props)
    }
    fnMenu = (option) => {
        if (option) {
            $('.header-main,.page-inicio').removeClass('close-menu').addClass('active');
            $('html,body').css({'overflow':'hidden'})
        }else{
            $('.header-main,.page-inicio').removeClass('active').addClass('close-menu');
            $('html,body').css({'overflow':'auto'})
        }
    }
    openCart(){
        document.querySelector(".dialog-cart").open = true;
    }
    render() {
        return (
            <div className={"header-main"}>
            <header className="header w-100">
                <div className="w-80 m-auto bg-white d-flex justify-content-between align-items-center">
                    <div className="logo">
                        <a href="/">
                            <img src={require("Assets/images/logo.svg")} className="img img-fluid" />
                        </a>
                    </div>
                    {/* <div className="d-flex align-items-center PoppinsMedium">
                        <div class="cart movil-show"  onClick={this.openCart.bind(this)}>
                            <div class="icon-cart"></div>
                            <div class="contador PoppinsMedium">0</div>
                        </div>
                        <div class="cart movil-show">
                            <div class="icon-user"></div>
                        </div>
                    </div> */}
                
                    <div className="menu align-items-center">
                        <ul className="d-flex">
                            {/*<li className="active">
                                <a href="" className="link">
                                    Inicio
                                </a>
                            </li>
                            <li>
                                <a href="" className="link">
                                    Servicios
                                </a>
                            </li>
                            <li>
                                <a href="" className="link">
                                    Nosotros
                                </a>
                            </li>
                            <li>
                                <a href="" className="d-flex link">
                                    <img 
                                        src={require("Assets/images/iconos/carrito_corregido.svg")} 
                                        style={{width:20}} 
                                        className="img img-fluid"
                                    />&nbsp;Catálogo
                                </a>
                            </li>
                            <li>
                                <a href="" className="link">
                                    Blog
                                </a>
                            </li>
                            <li className="ml-2 mr-2">
                                <a href="#" className="btn btn-danger">
                                    Resultados en línea
                                </a>
                            </li>
                            <li className="ml-2 mr-2">
                                <a href="#" className="btn btn-primary">
                                    Análisis a domicilio
                                </a>
                            </li>*/}
                            <li className="ml-2 mr-2">
                                            <a href="tel:017778498" className="link-down text-primary d-flex align-items-center PoppinsMedium c-primary">
                                                <div class="icon-call"></div>
                                                &nbsp;&nbsp;&nbsp;(01) 777 8498
                                            </a>

                            </li>
                            <li className="ml-2 mr-2">
                                <div className="button">
                                    <div className="button-contact">
                                        <img className="icon-whatsapp" src={require("../Assets/images/iconos/whatsapp.png")}/>
                                        <span className="title">Contáctanos</span>
                                        <div className="icon-down down-button"></div>
                                    </div>
                                    <div className="contacts">
                                        <div className="content-link-contact">
                                        <a href="https://wa.me/51934960232" target="_blank" className=" link-down whatsapp text-primary d-flex align-items-center PoppinsMedium c-primary">
                                                <img 
                                                src={require("../Assets/images/iconos/whatsapp.png")} 
                                                style={{height:37}}
                                                className="img img-fluid icon-whatsapp"
                                            />
                                            &nbsp;&nbsp;&nbsp;934 960 232
                                            <span class="line"></span>
                                        </a>
                                        <a href="https://wa.me/51946436787" target="_blank" className=" link-down whatsapp text-primary d-flex align-items-center PoppinsMedium c-primary">
                                                <img 
                                                src={require("../Assets/images/iconos/whatsapp.png")} 
                                                style={{height:37}}
                                                className="img img-fluid icon-whatsapp"
                                            />
                                            &nbsp;&nbsp;&nbsp;946 436 787
                                            <span class="line"></span>
                                        </a>
                                        <a href="https://wa.me/51946373612" target="_blank" className=" link-down whatsapp text-primary d-flex align-items-center PoppinsMedium c-primary">
                                                <img 
                                                src={require("../Assets/images/iconos/whatsapp.png")} 
                                                style={{height:37}}
                                                className="img img-fluid icon-whatsapp"
                                            />
                                            &nbsp;&nbsp;&nbsp;946 373 612
                                            
                                        </a>
                                        <a href="https://wa.link/k46gke" target="_blank" className=" link-down whatsapp text-primary d-flex align-items-center PoppinsMedium c-primary">
                                                <img 
                                                src={require("../Assets/images/iconos/whatsapp.png")} 
                                                style={{height:37}}
                                                className="img img-fluid icon-whatsapp"
                                            />
                                            &nbsp;&nbsp;&nbsp;977 565 594
                                            
                                        </a>
                                        </div>
                                    </div>
                                </div>
                                
                            </li>
                            

                            {/* <div className="separador"></div>
                            <li className="ml-1 mr-2">
                                <div className="user-session">
                                    <div className="user"><div className="icon-user"></div></div>
                                    <div className="PoppinsMedium">Inicia Sesión</div>
                                </div>
                            </li> */}
                            <li className="ml-2 mr-2">
                                <a href="/resultados/login" className="result btn btn-danger  d-flex align-items-center PoppinsMedium">
                                        Resultados en linea
                                </a>
                            </li>
                            {/* <li className="ml-0 mr-2">
                                <div className="content-cart" onClick={this.openCart.bind(this)}>
                                    <div className="cart"><div className="icon-cart"></div><div className="contador PoppinsMedium">0</div></div>
                                    <div className="PoppinsMedium">Carrito</div>
                                </div>
                            </li>
                            
                            <div className="separador"></div> */}
                            <li className="ml-2 mr-2">
                                <a href="https://instagram.com/inmunimed.pe" target="_blank" className="text-primary c-primary PoppinsMedium d-flex align-items-center fa-2x">
                                    <div class="icon-instagram"></div>
                                </a>
                            </li>
                            <li className="ml-0 mr-2">
                                <a href="https://www.facebook.com/inmunimed/" target="_blank" className="text-primary c-primary PoppinsMedium d-flex align-items-center fa-2x">
                                    <div class="icon-facebook"></div>
                                </a>
                            </li>
                            
                        </ul>
                    </div>
                    <MediaQuery query="(max-width:590px)">
                        <div className="content-bars-menu" style={{height:'100%'}} onClick={(e) => this.fnMenu(true)}>
                            <div className="bar" />
                            <div className="bar" />
                            <div className="bar" />
                        </div>
                    </MediaQuery>
                </div>
            </header>
            <MediaQuery query="(max-width:590px)">
                <div className="content-degradado" />
                <div className="menu-movil-content">
                    <div className="content-menu d-flex align-items-center flex-column">
                        <div className="button-close-menu shadow-sm d-flex align-items-center GtWalsheimProBold" style={{width:'fit-content', height:'auto', borderRadius:'50%'}} onClick={(e) => this.fnMenu(false)}>
                            <div className="close" style={{WebkitMaskImage:`url(${require("Assets/images/close.svg")})`}} />
                        </div>
                        {/* <ul className="menu d-flex justify-content-center align-items-center flex-column w-100">
                            <li className="m-3 mt-5 active" style={{fontSize:'1.3rem'}}>
                                <a
                                    href={"#home"}
                                >
                                    Inicio
                                </a>
                            </li>
                            <li className="m-3" style={{fontSize:'1.3rem'}}>
                                <a
                                    href={"#services"}
                                >
                                    Servicios
                                </a>
                            </li>
                            <li className="m-3" style={{fontSize:'1.3rem'}}>
                                <a
                                    href={"#aboutus"}
                                >
                                    Nosotros
                                </a>
                            </li>
                            <li className="m-3" style={{fontSize:'1.3rem'}}>
                                <a
                                    href={"#catalogue"}
                                >
                                    Catálogo
                                </a>
                            </li>
                            <li className="m-3" style={{fontSize:'1.3rem'}}>
                                <a
                                    href={"#blog"}
                                >
                                    Blog
                                </a>
                            </li>
                        </ul> */}
                        
                        <div className="box-bottom d-flex flex-column justify-content-center align-items-center" style={{borderTop:0}}>

                            <a href="/resultados/login" className="btn btn-danger  d-flex align-items-center PoppinsMedium">
                                        Resultados en linea
                            </a>
                            <a href="https://wa.me/934 960 232" className="whatsapp text-primary d-flex align-items-center PoppinsMedium c-primary">
                                        <img 
                                        src={require("../Assets/images/iconos/whatsapp.png")} 
                                        style={{height:37}}
                                        className="img img-fluid"
                                    />
                                     &nbsp;&nbsp;&nbsp;934 960 232
                            </a>
                            <a href="https://wa.me/51946 436 787" className="whatsapp text-primary d-flex align-items-center PoppinsMedium c-primary">
                                        <img 
                                        src={require("../Assets/images/iconos/whatsapp.png")} 
                                        style={{height:37}}
                                        className="img img-fluid"
                                    />
                                     &nbsp;&nbsp;&nbsp;946 436 787
                            </a>
                            <a href="https://wa.me/51946373612" className="whatsapp text-primary d-flex align-items-center PoppinsMedium c-primary">
                                        <img 
                                        src={require("../Assets/images/iconos/whatsapp.png")} 
                                        style={{height:37}}
                                        className="img img-fluid"
                                    />
                                     &nbsp;&nbsp;&nbsp;946 373 612
                            </a>
                            <a href="https://wa.link/k46gke" className="whatsapp text-primary d-flex align-items-center PoppinsMedium c-primary">
                                        <img 
                                        src={require("../Assets/images/iconos/whatsapp.png")} 
                                        style={{height:37}}
                                        className="img img-fluid"
                                    />
                                     &nbsp;&nbsp;&nbsp;977 565 594
                            </a>
                            <a href="tel:017778498" className="item text-primary d-flex align-items-center PoppinsMedium c-primary">
                                <img 
                                    src={require("../Assets/images/iconos/callcenter.png")} 
                                    style={{height:37}}
                                    className="img img-fluid"
                                />
                                    &nbsp;&nbsp;&nbsp;(01) 777 8498
                            </a>

                            {/* <a href="tel:017778498" className="text-primary d-flex align-items-center PoppinsMedium c-primary">
                                <img 
                                    src={require("Assets/images/footer/instagram.svg")} 
                                    style={{height:37}}
                                    className="img img-fluid"
                                />
                            </a>
                            <a href="tel:017778498" className="text-primary d-flex align-items-center PoppinsMedium c-primary">
                                <img 
                                    src={require("Assets/images/footer/facebook.svg")} 
                                    style={{height:37}}
                                    className="img img-fluid"
                                />
                            </a> */}
                            {/* <button className="btn btn-danger mt-4" style={{height:'3rem'}}>
                                Resultados en línea
                            </button>
                            <button className="btn btn-primary mt-2" style={{height:'3rem'}}>
                                Análisis a domicilio
                            </button> */}
                        </div>
                        <footer className="footer-movil">
                            
                            <a href="https://instagram.com/inmunimed.pe" target="_blank" className="text-primary c-primary PoppinsMedium ml-4 d-flex align-items-center fa-2x">
                                <div class="icon-instagram"></div>
                            </a>
                            <a href="https://www.facebook.com/inmunimed/" target="_blank" className="text-primary c-primary PoppinsMedium d-flex align-items-center fa-2x">
                                <i class="icon-facebook"></i>
                            </a>
                        </footer>
                    </div>
                </div>
            </MediaQuery>
            </div>
        )
    }
}
