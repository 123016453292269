import React, { Component } from 'react'
import "./cart.scss"

export default class Cart extends Component {
    componentDidMount(){
        
    }
   
    render() {
        return (
            <dialog open className="dialog-cart" data-type="modal" >
            
            </dialog>
        )
    }
}
